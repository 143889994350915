.item {
    margin: 15px;
    padding: 10px;
    background: var(--light-text-color);
    border-radius: 12px;
    display: flex;
    margin-bottom: 0px;
    align-items: center;
    justify-content: space-between;
    gap: 10px;
    width: calc(100% - 30px);

    border: 1px solid var(--border-color);
}

.item:first-of-type {
    margin-top: 0;
}

.cart {
    display: flex;
    flex-direction: column;
    margin-bottom: 100px;
    align-items: start;
}

.info {
    display: flex;
    gap: 10px;
    align-items: start;
    flex: 1;
}

.empty {
    text-align: center;
    color: #000;
    font-weight: 400;
    font-size: 17px;
    padding: 10px;
    border-radius: 12px;
    margin: 15px;
    border: 1px solid var(--border-color);
    width: calc(100% - 30px);
}

.description {
    width: 100%;
}

.delete__icon {
    width: 30px;
    height: 30px;
    color: var(--text-color);
}

.button__delete {
    display: flex;
    align-items: center;
}

.title {
    padding-top: 20px;
    font-size: 24px;
    padding-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
    margin-bottom: 15px;
    margin-left: 15px;
    margin-right: 15px;
    font-weight: 600;
}

.name {
    font-weight: 500;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 300px;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 5px;
    margin-bottom: 5px;
}

.price {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding-top: 10px;
}

.image__wrapper {
    width: 50px;
    height: 50px;
    margin: 0;
}

.image {
    aspect-ratio: auto;
    width: 50px;
    height: 50px;
    border-radius: 10px;
    object-fit: cover;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px;
    width: 100%;
    background-color: var(--secondary-background);
    box-shadow: 0 -4px 24px rgba(0, 0, 0, .06);
}

.options {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.option {
    display: flex;
    flex-direction: column;
    padding-bottom: 5px;
    border-bottom: 1px solid var(--border-color);
    font-size: 14px;
}

.option__text {
    font-size: 14px;
}

.footer__btn {
    background-color: var(--payment-background);
    border: none;
    border-radius: 12px;
    color: var(--light-text-color);
    font-weight: 600;
    height: 50px;
    display: flex;
    padding: 20px;
    width: 100%;
    justify-content: center;
    align-items: center;
}

.loader__wrapper {
    display: flex;
    justify-content: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    align-items: center;
}

.count {
    display: flex;
    align-items: center;
    gap: 10px;
}

.count__icon {
    border: 1px solid var(--border-color);
    border-radius: 5px;
    width: 30px;
    height: 15px;
}

.price__total {
    font-size: 16px;
}

@media (max-width: 320px) {
    .price__total {
        font-size: 14px;
    }
}