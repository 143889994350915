@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@400;600;700&family=Roboto:wght@300;400;500;700&display=swap');

:root {
    --page-background: #f6f6f6;
    --secondary-background: #fff;
    --navbar-icon-color: #B8B8B8;
    --border-color: #dedede;
    --button-text-color: #f6f6f6;
    --parameter-border-color: #000;
    --button-background: #111;
    --delete-button-color: #efefef;
    --light-text-color: #fff;
    --text-color: #484848;
    --dark-text-color: #000;
    --sale-color: #b8b8b8;
    --payment-background: #5AA200;
    --switch-border-color: #acacac;
    --handle-background: #999999;
    --product-out: #d6d6d6;
}

body {
    margin: 0;
    padding: 0;
    font-family: 'Roboto', sans-serif;
    background-color: var(--secondary-background);

    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
}

.modal-open {
    overflow: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}

* {
    box-sizing: border-box;
}

ul {
    list-style: none;
    padding: 0;
    margin: 0;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
}

p {
    margin: 0;
}

* {
    -ms-overflow-style: none;
    /* IE и Edge */
    scrollbar-width: none;
    /* Firefox */
}

*::-webkit-scrollbar {
    display: none;
    /* Chrome, Safari, Opera*/
}

*:focus {
    outline: none;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    -webkit-touch-callout: none;
}