.catalog__title {
    padding-top: 20px;
    font-size: 24px;
    padding-bottom: 20px;
    font-family: 'Montserrat', sans-serif;

    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
    font-weight: 600;
    margin-bottom: 15px;
}