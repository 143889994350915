/* BuyAll.module.css */

.buy__all {
    width: 100%;
}

.buy__all__info__list {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.buy__all__info {
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.buy__all__info__item__icon {
    width: 20px;
    height: 20px;
}

.buy__all__info__item__options {
    border-radius: 10px;
    border: 1px solid;
    display: none;
}

.buy__all__info__item__options__open {
    display: block;
}

.buy__all__info__item__option {
    padding: 10px;
    border-bottom: 1px solid var(--border-color);
    /* background: #f3f3f3; */
}

.buy__all__info__item__option:active {
    background: var(--button-background);
    color: var(--light-text-color);
}

.buy__all__info__item__option:first-of-type {
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.buy__all__info__item__option:last-of-type {
    border-bottom: none;
    border-bottom-left-radius: 10px;
    border-bottom-right-radius: 10px;
}

.buy__all__info__item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px;
    border: 1px solid;
    border-radius: 10px;
}

.price {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    /* height: 60px; */
    background-color: var(--secondary-background);
}

.item__price {
    font-weight: 500;
    padding: 10px;
    font-size: 20px;
    padding-bottom: 0px;
}

.discount {
    display: flex;
}

.item__discount {
    text-decoration: line-through;
}

.title {
    font-family: 'Montserrat', sans-serif;
    font-size: 20px;
    margin-top: 15px;
    font-weight: 500;
}

.params {
    margin-top: 15px;
    display: flex;
    justify-content: center;
}

.params p {
    border-right: 1px solid var(--parameter-border-color);
    padding-right: 10px;
    padding-left: 10px;
}

.params p:last-child {
    border-right: none;
}

.delivery {
    margin-top: 30px;
    text-align: center;
}

.info {
    transition: all 0.3s ease;
}

.sale {
    color: var(--sale-color);
    transition: all 0.3s ease;
}

.selectedOption {
    background: var(--button-background);
    color: var(--light-text-color);
}