/* FullScreenNotification.module.css */
.notificationWrapper {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    z-index: 99999;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
}

.notification {
    padding: 20px;
    margin: 20px;
    background: white;
    border-radius: 5px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    text-align: center;
}

.message {
    margin-bottom: 20px;
    font-size: 20px;
}

.buttons {
    display: flex;
    justify-content: center;
    gap: 10px;
}

.button {
    padding: 10px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    background-color: var(--button-background);
    color: var(--secondary-background);
    font-weight: 600;
}