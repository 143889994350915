.header {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: sticky;
    top: -37px;
    z-index: 2222;
    background: transparent;
}

.form {
    padding-top: 10px;
    margin-top: 20px;
    border-top: 1px solid;
}

.subtitle {
    font-size: 16px;
    font-weight: 400;
    margin-top: 10px;
}

.field {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 10px;
}

.input {
    height: 40px;
    border-radius: 10px;
    border: 1px solid var(--border-color);
    width: 100%;
    padding: 10px;
}

.inputs {
    display: flex;
    align-items: center;
    gap: 10px;
}

.delete {
    width: 40px;
    height: 40px;
    border: 1px solid var(--border-color);
    padding: 8px;
    border-radius: 10px;
    cursor: pointer;
}

.add__btn,
.add__element {
    text-align: center;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    background-color: var(--button-background);
    color: var(--light-text-color);
    font-weight: 600;
    width: 100%;
}

.change__header {
    top: -21px;
}

.add__element {
    margin-top: 10px;
    background-color: transparent;
    color: var(--button-background);
}