.modal__wrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    border: none;
    width: 100%;
    bottom: 0;
    height: 100dvh;
    background-color: var(--secondary-background);
    overflow: scroll;
}

.close__btn {
    position: absolute;
    width: 25px;
    height: 25px;
    top: 20px;
    right: 20px;
    color: var(--dark-text-color);
}

.modal {
    margin-bottom: 100px;
}

.form {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.field {
    display: flex;
    flex-direction: column;
    position: relative;
    gap: 10px;
}

.label {
    font-size: 14px;
}

.input {
    width: 100%;
    padding: 12px;
    height: 45px;
    border-radius: 12px;
    border: 1px solid var(--border-color);
    outline: none;
}

.item__picture {
    width: 100%;
    padding-top: 100%;
    position: relative;
    overflow: hidden;
    border-radius: 12px;
}

.item__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
    border-radius: 12px;
}

.input[type="file"] {
    position: absolute;
    opacity: 0;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    cursor: pointer;
}

.custom__file__upload {
    text-align: center;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px;
    width: 100%;
    z-index: 99999;
    background-color: var(--secondary-background);
    box-shadow: 0 -4px 24px rgba(0, 0, 0, .06);
}

.create__btn {
    border-radius: 12px;
    color: var(--light-text-color);
    border: none;
    background-color: var(--button-background);
    font-weight: 600;
    width: 100%;
    height: 50px;
}

.error {
    text-align: center;
    font-size: 14px;
    color: red;
}

.error__input {
    color: red;
    border: 1px solid red;
}

.error__input::placeholder {
    color: red;
}

.add__btn {
    text-align: center;
    padding: 10px;
    border: 1px solid var(--border-color);
    border-radius: 12px;
    background-color: transparent;
}

.loader {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    align-items: center;
    background-color: #fff;
    z-index: 999999;
}

.loader__text {
    font-size: 20px;
    font-weight: 500;
}