.catalog {
    padding: 16px;
}

.title {
    font-size: 24px;
    padding-bottom: 20px;
    font-family: 'Montserrat', sans-serif;
    border-bottom: 1px solid var(--border-color);
    padding-bottom: 10px;
    margin-bottom: 15px;
    font-weight: 600;
}

.footer__btn {
    border-radius: 12px;
    padding: 15px;
    border: none;
    width: 100%;
    color: var(--button-background);
    font-weight: 600;
    background-color: var(--delete-button-color);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.footer {
    display: flex;
    flex-direction: column;
    gap: 10px;
    position: fixed;
    bottom: -1px;
    left: 0;
    padding: 15px;
    width: 100%;
    background-color: var(--secondary-background);
    box-shadow: 0 -4px 24px rgba(0, 0, 0, .06);
}

.footer__btns {
    display: flex;
    gap: 10px;
}

.create__btn {
    border-radius: 12px;
    padding: 18px;
    font-size: 14px;
    border: none;
    width: 100%;
    color: var(--light-text-color);
    font-weight: 600;
    background: var(--button-background);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
}

.catalog__container {
    margin-bottom: 100px;
}