.header {
    display: flex;
    background-color: var(--page-background);
    border-radius: 0px 0px 20px 20px;
    width: 100%;
    padding: 0 20px;
    padding-top: 15px;
    padding-bottom: 15px;
    height: 70px;
    position: sticky;
    top: -0.5px;
    z-index: 8888;
}

.cart__wrapper {
    display: flex;
    gap: 10px;
    align-items: center;
}

.navbar {
    display: flex;
    justify-content: space-between;
    width: 100%;
    align-items: center;
}

.profile {
    display: flex;
    align-items: center;
    gap: 10px;
}

.profile__name {
    color: var(--text-color);
}

.cart__icon {
    width: 25px;
    height: 25px;
    color: var(--navbar-icon-color);
}

.cart__count {
    color: var(--payment-background);
}

.profile__icon {
    width: 28px;
    height: 28px;
    color: var(--navbar-icon-color);
}

.profile__link {
    text-decoration: none;
    border-radius: 8px;
    border: 1px solid;
    color: var(--text-color);
    padding: 5px;
}

@keyframes pulse {
    0% {
        transform: scale(1);
        opacity: 1;
    }

    50% {
        transform: scale(1.1);
        opacity: 0.6;
    }

    100% {
        transform: scale(1);
        opacity: 1;
    }
}

.cart__green {
    color: var(--payment-background);
    animation: pulse 2s infinite;
}

@media (max-width: 300px) {
    .profile__link {
        font-size: 14px;
    }
}