.related__items {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow: scroll;
    z-index: 99999;
    background-color: var(--secondary-background);
    padding: 16px;
    padding-bottom: 100px;
}

.info {
    display: flex;
    justify-content: center;
    flex-direction: column;
    padding: 10px;
}

.related__items__container {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.related__items__list {
    display: flex;
    margin-top: 16px;
    flex-direction: column;
    gap: 10px;
}

.related__item {
    display: flex;
    background: var(--secondary-background);
    border-radius: 20px;
    box-shadow: 0 4px 24px rgba(53, 51, 57, .08);
}

.close__btn {
    width: 25px;
    height: 25px;
}

.image__container {
    position: relative;
    width: 80px;
    height: 80px;
}

.image {
    width: 80px;
    height: 80px;
    border-radius: 20px 0px 0px 20px;
    object-fit: cover;
}

.loader {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.related__item .info p {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
}

.check {
    position: absolute;
    width: 80px;
    height: 80px;
    border-radius: 20px 0px 0px 20px;
    z-index: 999;
    background-color: transparent;
    top: 0;
    left: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}

.dlc {
    background-color: rgba(5, 95, 19, 0.4);
}

.check__icon {
    width: 30px;
    height: 30px;
    color: #fff;
}

.save__btn {
    height: 50px;
}

.footer {
    position: fixed;
    bottom: 0;
    left: 0;
    padding: 15px;
    width: 100%;
    z-index: 99999;
    background-color: var(--secondary-background);
    box-shadow: 0 -4px 24px rgba(0, 0, 0, .06);
}

.create__btn {
    border-radius: 12px;
    color: var(--light-text-color);
    border: none;
    background-color: var(--button-background);
    font-weight: 600;
    width: 100%;
    height: 50px;
}